@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@200;400;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Barlow", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --psRed: #ff3939;
  --psRedHover: #ff7070;
}

@media screen and (min-width: 769px) {
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #eee;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--psRed);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--psRedHover);
  }
}

.psDrawer {
  width: 90vw !important;
  max-width: 400px !important;
  height: 100vh !important;
  overflow-y: scroll !important;
}

.non-decored {
  text-decoration: none;
}

ul {
  list-style: none;
}

.react-multiple-carousel__arrow--right,
.react-multiple-carousel__arrow--left {
  z-index: 3 !important;
}

@media screen and (max-width: 400px) {
  .margin-text-control {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 762px) {
  .mobile-row {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

@media screen and (min-width: 670px) {
  .ReactModal__Content {
    left: 50% !important;
    margin-left: -300px;
  }
}

@media screen and (max-width: 796px) {
  .mobile-row {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .center-mobile {
    margin: auto;
  }
}
